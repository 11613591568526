/**
 * Scripts
 */

import bodyClass from '../scripts/bodyclass';
import globalScrollListener from '../scripts/globalScrollListener';
import globalResizeListener from '../scripts/globalResizeListener';
import '../scripts/lazyloading';
import '../scripts/fontfaceobserver';

// Modules Array
const scriptsArray = [
    bodyClass,
    globalScrollListener,
    globalResizeListener,
];

const scripts = {
    // Scripts
    Scripts() {
        if (scriptsArray.length > 0 && scriptsArray !== undefined) {
            scriptsArray.forEach((script) => {
                script.init();
            });
        }
    },

    init() {
        this.Scripts();
    }
};

export default scripts;
