/*
 * Description of what this file is for
 *
 * @package  pl-klinik
 * @author Sarah Schütz [sarah@fredmansky.at]
 * navMain
 */

import _throttle from 'lodash/throttle';

const navMain = {
    cfg: {
        name: 'navMain',
        winWidth: localStorage.getItem('vw'),
        selectors: {
            navMain: '.m-navMain',
            listItemLevel1: '.js-trigger-level-1',
            navMainTrigger: '.js-navMain__trigger'
        },
        classes: {
            active: 'is-active',
            inActive: 'is-inActive',
            hidden: 'is-hidden',
            visible: 'is-visible',
            open: 'is-navOpen',
        },
        el: {
            $navMain: undefined,
            $navMainTrigger: undefined
        },
        els: {
            $listItemsLevel1: undefined
        }
    },

    setElements() {
        this.cfg.el.$navMain = document.querySelector(this.cfg.selectors.navMain);
        this.cfg.el.$navMainTrigger = document.querySelector(this.cfg.selectors.navMainTrigger);
        this.cfg.els.$listItemsLevel1 = [
            ...this.cfg.el.$navMain.querySelectorAll(this.cfg.selectors.listItemLevel1)
        ];
    },

    handleDesktopNav() {
        this.cfg.els.$listItemsLevel1.forEach((listItem) => {
            let timer;
            listItem.addEventListener('mouseenter', () => {
                clearTimeout(timer);
                listItem.classList.add(this.cfg.classes.active);
            });
            listItem.addEventListener('mouseleave', () => {
                timer = setTimeout(() => {
                    listItem.classList.remove(this.cfg.classes.active);
                }, 250);
            });
        });
    },

    handleMobileNav() {
        this.cfg.el.$navMainTrigger.addEventListener('click', () => {
            if (document.body.classList.contains(this.cfg.classes.open)) {
                document.body.classList.remove(this.cfg.classes.open);
            } else {
                document.body.classList.add(this.cfg.classes.open);
            }
        });
    },

    init() {
        this.setElements();

        if (this.cfg.winWidth >= 1000) {
            this.handleDesktopNav();
        } else {
            this.handleMobileNav();
        }

        // Resize check all again
        const winResize = _throttle(() => {
            this.cfg.winWidth = localStorage.getItem('vw');
        }, 500);

        window.addEventListener('resize', winResize);
    }
};

export default navMain;
